// blog style hear
.rts-blog-area{
    position: relative;
    z-index: 5;
    .title-area{
        span{
            color: var(--color-primary);
            font-weight: 700;
            text-transform: uppercase;
        }
        .title{
            color: #fff;
        }
    }
}

.single-blog-one-wrapper{
    &:hover{
        .thumbnail{
            img{
                filter: grayscale(1);
            }
        }
    }
    .thumbnail{
        margin-bottom: 36px;
        position: relative;
        img{
            width: 100%;
            height: auto;
            margin-bottom: 0;
        }
        .blog-badge{
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 5px 31px;
            background: var(--color-primary);
            span{
                color: #fff;
                display: block;
            }
        }
    }
    .blog-content{
        p{
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 10px;
            color: #FFFFFF;
            span{
                color: var(--color-primary);
                font-weight: 500;
            }
        }
        a{
            .title{
                color: #fff;
                padding-right: 15px;
                margin-bottom: 30px;
                transition: var(--transition);
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        a{
            color: #fff;
            i{
                background: #2B2F39;
                color: #fff;
            }
            &:hover{
                i{
                    background: var(--color-primary);
                }
            }
        }
    }
}



// blog page area


.rts-single-wized{
    background: #F6F6F6;
    border-radius: 15px;
    padding: 40px;
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0;
    }
    @media #{$small-mobile} {
        padding: 20px;
    }
    &.service{
        border-radius: 0;
        .single-categories{
            li{
                a{
                    border-radius: 0;
                }
            }
        }
    }
    &.download{
        background: #1C2539;
        .title{
            color: #fff;
        }
        .single-download-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #2E3951;
            &:first-child{ 
                padding-top: 0;
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
            }
            .mid{
                margin-right: auto;
                margin-left: 15px;
                .title{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-family: var(--font-primary);
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                }
            }
            a{
                &.rts-btn{
                    padding: 11px 15px;
                    border-radius: 0;
                }
            }
        }
    }
    &.contact{
        background: #1C2539;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        a{
            img{
                width: 75%;
                margin: auto;
            }
        }
        @media #{$small-mobile} {
            padding: 25px 20px;
        }
        &:last-child{
            @media #{$small-mobile} {
                margin-bottom: 0;
            }
        }
        .wized-body{
            text-align: center;
            .title{
                color: #fff;
                margin-bottom: 30px;
                font-size: 22px;
                line-height: 32px;
            }
            a{
                &.rts-btn{
                    display: block;
                    max-width: max-content;
                    margin: auto;
                }
            }
        }
    }
    .wized-header{
        .title{
            margin-bottom: 10px;
        }
    }
    .wized-body{
        margin-top: 30px;
        .rts-search-wrapper{
            position: relative;
            input{
                background: #fff;
                height: 55px;
                border-radius: 5px;
                padding-right: 70px;
                padding-left: 25px;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                position: absolute;
                max-width: max-content;
                height: 55px;
                width: 55px;
                border-radius: 5px;
                background: var(--color-primary);
                display: inline-block;
                padding: 0 19px;
                right: 0;
                i{
                    color: #fff;
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }
    }
    .single-categories{
        margin-bottom: 15px;
        padding: 0;
        &:last-child{
            margin-bottom: 0;
        }
        li{
            list-style: none;
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 25px;
                background: #fff;
                color: #5D666F;
                font-weight: 500;     
                transition: .3s;  
                border-radius: 5px;         
                i{
                    color: var(--color-primary);
                    transition: .3s;
                }
                &:hover{
                    background: var(--color-primary);
                    transform: translateY(-5px) scale(1.03);
                    color: var(--color-white);  
                    i{
                        color: #fff;
                    }  
                }
            }
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 5px;
            img{
                min-width: 85px;
                height: auto;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    // gallery post
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 6px;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 97px;
                    height: auto;
                    transition: .3s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: 269px;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 16px;
            background: #fff;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .3s;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
            }
        }
    }
}

.blog-single-post-listing{
    margin-right: 30px;
    border: 1px solid #E6E9F0;
    margin-bottom: 50px;
    @media #{$smlg-device} {
        margin-right: 0;
    }
    // details style hear...
    &.details{
        border-radius: 15px;
        .thumbnail{
            border-radius: 15px 15px 0 0;
            &.details{
                border-radius: 15px;
                width: 100%;
                max-width: max-content;
                @media #{$smlg-device} {
                    max-width: 100%;
                }
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 20px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 0;
                }
            }
        }
        .rts-quote-area{
            padding: 50px;
            background: #F6F6F6;
            border-radius: 15px;
            margin-bottom: 40px;
            @media #{$md-layout} {
                padding: 30px;
            }
            @media #{$sm-layout} {
                padding: 10px;
                margin-bottom: 25px;
            }
            @media #{$small-mobile} {
                margin-top: 15px;
            }
            .title{
                margin-bottom: 25px;
                @media #{$small-mobile} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            .name{
                font-size: 18px;
                color: var(--color-primary);
                font-weight: 700;
            }
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #5D666F;
            }
        }
        .check-area-details{
            .single-check{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                i{
                    margin-right: 15px;
                    color: var(--color-primary);
                    @media #{$small-mobile} {
                    margin-top: -26px;
                    }
                }
                span{
                    color: #5D666F;
                }
            }
        }
        .details-tag{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media #{$small-mobile} {
                justify-content: flex-start;
            }
            h6{
                margin-bottom: 0;
                font-size: 18px;
                margin-right: 15px;
            }
            button{
                padding: 8px 12px;
                background: #F6F6F6;
                max-width: max-content;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 5px;
                color: #1C2539;
                transition: .3s;
                &:last-child{
                    @media #{$laptop-device} {
                        margin-top: 10px;
                        margin-left: -2px;
                    }
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: translateY(-2px) scale(1.02);
                }
            }
        }
        .details-share{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media #{$md-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$sm-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                justify-content: flex-start;
                margin-top: 20px;
            }
            button{
                max-width: max-content;
                position: relative;
                z-index: 1;
                margin-left: 23px;
                color: #1C2539;
                transition: .3s;
                font-size: 14px;
                &::after{
                    position: absolute;
                    content: '';
                    background: #F6F6F6;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: .3s;
                }
                &:hover{
                    color: #fff;
                    transform: scale(1.2);
                    &::after{
                        background: var(--color-primary);
                    }
                }
            }
            h6{
                font-size: 18px;
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .author-area{
            margin-top: 44px;
            display: flex;
            align-items: center;
            padding: 40px 0;
            border-top: 1px solid #E6E9F0;
            border-bottom: 1px solid #E6E9F0;
            @media #{$sm-layout} {
                align-items: flex-start;
            }
            @media #{$large-mobile} {
                flex-wrap: wrap;
            }
            .thumbnail{
                margin-right: 30px;
                @media #{$sm-layout} {
                    margin-right: 0;
                }
            }
            .author-details{
                @media #{$sm-layout} {
                    margin-left: 15px;
                }
                h5{
                    margin-bottom: 10px;
                }
                p{
                    line-height: 26px;
                }
            }
        }

    }
    &.inner{
        border: none;
        max-width: 950px;
        margin: 0 auto;
    }
    &.inner2{
        .blog-listing-content{
            .rts-quote-area{
                position: relative;
                background: #000;
                padding-top: 120px;
                &::before{
                    position: absolute;
                    left: 50%;
                    top: 40px;
                    transform: translate(-50%,0);
                    content: "\f10d";
                    font-family: "Font Awesome 5 Pro";
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 50%;
                    background: #fff;
                    color: var(--color-primary);
                    font-weight: 900;
                    font-size: 20px;
                }
                .title{
                    color: #f7f7f7;
                }
                
            }
        }
    }

    .replay-area-details{
        margin-top: 40px;
        form{
            input{
                height: 55px;
                border-radius: 15px;
                background: #F6F6F6;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                border-radius: 15px;
                background: #F6F6F6;
                height: 140px;
                margin-top: 20px;
                padding: 15px;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }


    .thumbnail{
        overflow: hidden;
        img{
            transition: .3s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .blog-listing-content{
        padding: 50px;
        @media #{$sm-layout} {
            padding: 25px 10px;
        }
        .user-info{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            flex-wrap: wrap;
            .single{
                margin-right: 30px;
                min-width: max-content;
                @media #{$sm-layout} {
                    margin-right: 5px;
                }
                @media #{$large-mobile} {
                    margin-right: 5px;
                }
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                    @media #{$large-mobile} {
                        margin-right: 2px;
                        font-size: 14px;
                    }
                }
                span{
                    @media #{$large-mobile} {
                        font-size: 13px;
                    }
                }
            }
        }
        .blog-title{
            transition: .3s;
            .title{
                transition: .3s;
                margin-bottom: 16px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
                // margin-bottom: 32px;
                @media #{$small-mobile} {
                    margin-bottom: 15px;
                }
            }
        }
        a{
            &.rts-btn{
                margin-top: 35px;
                display: block;
                max-width: max-content;
                @media #{$small-mobile} {
                    margin-top: 20px;
                }
            }
        }
    }
}

.disc{
    &.para-1{
        margin-bottom: 20px;
    }
}


// blog-threee

.rts-latest-blog-three{
    .blog-three{
        span{
            color: var(--color-primary-3);
            font-weight: 500;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            font-size: 16px;
        }
        .title{
            text-transform: uppercase;
            margin-top: 7px;
        }
    }
}


.blog-three-wrapepr{
    a{
        &.main-thumbnail{
            margin: 0 auto;
            display: flex;
            justify-content: center;
            overflow: hidden;
            display: block;
            max-width: max-content;
            border-radius: 10px;
            position: relative;
            z-index: 2;
            .badge-blog{
                position: absolute;
                top: 0;
                left: 0;
                padding: 7px 24px;
                background: var(--color-primary-3);
                color: #fff;
                font-size: 16px;
            }
            img{
                max-width: max-content;
                margin: 0 auto;
                border-radius: 10px;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
    }
    .blog-body{
        position: relative;
        margin-top: -30px;
        z-index: 1;
        .blog-header{
            background: #F6F6F6;
            border-radius: 10px 10px 0 0;
            padding: 50px 33px 20px 33px;
            display: flex;
            align-items: center;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .left{
                display: flex;
                align-items: center;
                i{
                    color: var(--color-primary-3);
                    font-size: 16px;
                }
                span{
                    color: #5D666F;
                    margin-left: 7px;
                    position: relative;
                    margin-bottom: -2px;
                    font-size: 16px;
                    @media #{$laptop-device} {
                        font-size: 13px;
                    }
                    @media #{$smlg-device} {
                        font-size: 13px;
                    }
                }
            }
            .right{
                margin-left: 25px;
                display: flex;
                align-items: center;
                @media #{$laptop-device} {
                    margin-left: 15px;
                }
                @media #{$smlg-device} {
                    margin-left: 15px;
                }
                @media #{$large-mobile} {
                    margin-left: 0;
                }
                i{
                    color: var(--color-primary-3);
                    font-size: 16px;
                }
                span{
                    color: #5D666F;
                    margin-left: 7px;
                    position: relative;
                    font-size: 16px;
                    margin-bottom: -2px;
                    @media #{$laptop-device} {
                        font-size: 13px;
                    }
                    @media #{$smlg-device} {
                        font-size: 13px;
                    }
                }
            }
        }
        .body{
            padding: 30px 32px 32px 32px;
            box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
            border-radius: 0px 0px 10px 10px;
            @media #{$smlg-device} {
                padding: 20px;
            }
            @media #{$large-mobile} {
                padding: 20px;
            }
            a{
                transition: .3s;
                &:hover{
                    .title{
                        color: var(--color-primary-3);
                    }
                }
                &:hover{
                    background: var(--color-primary-3);
                    color: #fff;
                }
            }
            .title{
                font-size: 24px;
                line-height: 34px;
                color: #1C2539;
                @media #{$smlg-device} {
                    font-size: 18px;
                    line-height: 27px;
                }
                @media #{$large-mobile} {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}


.rts-blog-h-2-wrapper{
    padding: 40px;
    border-radius: 30px;
    border: 1px solid #EDEFF3;
    @media #{$large-mobile} {
        padding: 15px;
    }
    .thumbnail{
        overflow: hidden;
        display: block;
        max-width: max-content;
        margin-bottom: 30px;
        border-radius: 30px;
        img{
            width: 100%;
            transition: .5s;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .body{
        span{
            color: #F64A00;
            font-size: 16px;
            font-weight: 500;
        }
        a{
            .title{
                margin-top: 15px;
                transition: .3s;
                color: #1C2539;
                font-weight: 700;
                line-height: 40px;
                @media #{$md-layout} {
                    br{
                        display: none;
                    }
                }
                @media #{$small-mobile} {
                    font-size: 18px;
                    line-height: 29px;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary-2);
                }
            }
        }
        .rts-read-more{
            color: #1C2539;
            &:hover{
                i{
                    background: var(--color-primary-2);
                    color: #fff;
                }
                color: var(--color-primary-2);
            }
        }
        .rts-read-more i{
            background: #F5F5F5;
            color: #000;
        }
    }
}
.rts-blog-list-area{
    .pagination{
        margin-top: 10px;
    }
    &.inner{
        background: var(--color-gray);
    }
}


.thumbnail{
    &.details{
        &.team{
            @media #{$small-mobile} {
                margin-bottom: 15px;
            }
        }
    }
}
.thumbnail.details.team {
    @media #{$small-mobile} {
        margin-bottom: 15px;
    }
}
.thumbnail.details.team{
    @media #{$small-mobile} {
        margin-top: 20px;
    }
}


.pr_sm-controler--0{
    @media #{$sm-layout} {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
    }
}

.rts-blog-area4{
    .container{
        .title-service-three{
            .pre-title{
                color: var(--color-primary-4);
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                text-transform: uppercase;
                letter-spacing: 0.1em;
            }
            .title{
                text-transform: none;
            }
        }
        .service-one-inner-four{
            .big-thumbnail-area{
                background: #fff;
                border-radius: 15px;
                padding: 40px;
                box-shadow: 0px 2px 20px rgba(24, 16, 16, .09);
                &::after{
                    display: none;
                }
                .content{
                    position: unset;
                    height: auto;
                    margin-top: 25px;
                    .title{
                        color: #1C2539;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #E8E8E8;
                    }
                }
                .author-box{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding-left: 25px;
                    .date{
                        margin: 0;
                        position: relative;
                        font-size: 16px;
                        &::before{
                            content: '\f017';
                            position: absolute;
                            font-family: 'Font Awesome 5 Pro';
                            left: -30px;
                            color: var(--color-primary-4);
                        }
                    }
                    .author{
                        margin-left: 40px;
                        position: relative;
                        font-size: 16px;
                        &::before{
                            content: '\f007';
                            position: absolute;
                            font-family: 'Font Awesome 5 Pro';
                            left: -25px;
                            color: var(--color-primary-4);
                        }
                    }
                }
            }
        }
    }
    &.blog-area5{
        .container{
            .title-service-three{
                .pre-title{
                    color: var(--color-primary-2);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                }
                .title{
                    text-transform: none;
                }
            }
            .service-one-inner-four{
                .big-thumbnail-area{
                    background: #f7f7f7;
                    border-radius: 15px;
                    padding: 0;
                    box-shadow: none;
                    &::after{
                        display: none;
                    }
                    .content-box{
                        padding: 40px;
                        transition: all .5s;
                        border-radius: 15px;
                        @media(max-width:500px){
                            padding: 30px 15px;
                        }
                    }
                    .thumbnail{
                        border-radius: 15px 15px 0 0;
                        position: relative;
                        img{
                            border-radius: 0;
                        }
                        .date{
                            position: absolute;
                            background: var(--color-primary-2);
                            color: #fff;
                            bottom: 0;
                            right: 0;
                            padding: 7px 25px;
                            border-radius: 15px 0 0 0;
                        }
                    }
                    .content{
                        position: unset;
                        height: auto;
                        margin: 5px 0;
                        transition: all .5s;
                        .title{
                            color: #1C2539;
                            padding-bottom: 0;
                            border: none;
                            margin-bottom: 15px;
                            transition: all .5s;
                        }
                        .desc{
                            margin: 0 0 20px 0;
                            transition: all .5s;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                    .author-box{
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        padding-left: 0;
                        transition: all .5s;
                        .author{
                            margin-left: 0;
                            font-size: 14px;
                            @media(max-width:400px){
                                font-size: 13px;
                            }
                            span{
                                color: var(--color-primary-2);
                                transition: all .5s;
                            }
                            &::before{
                                display: none;
                            }
                            
                        }
                    }
                    .button-area{
                        a{
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            width: 150px;
                            color: var(--color-primary-2);
                            i{
                                background: var(--color-primary-2);
                                color: #fff;
                                width: 40px;
                                height: 40px;
                                line-height: 40px;
                                display: block;
                                text-align: center;
                                border-radius: 50%; 
                                margin-right: 10px;
                                transition: all .3s;
                                &:hover{
                                    background: #fff;
                                    color: var(--color-primary-2);
                                }
                            }
                        }
                    }
                    &.area-5{
                        margin-bottom: 30px;
                        .content-box{
                            padding: 30px;
                            @media(max-width:500px){
                                padding: 30px 15px;
                                
                            }
                            .content{
                                .desc{
                                    margin-bottom: 0;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.rts-inner-blog{
    .blog-details{
        .blog-full{
            max-width: 900px;
            margin: 0 auto;
            blockquote {
                margin: 35px 0px;
                padding: 60px;
                padding-top: 85px;
                color: #666;
                position: relative;
                background: var(--color-gray);
                font-weight: normal;
                font-style: italic;
                text-align: left;
                clear: both;
                font-size: 20px;
                font-weight: 400;
                border-radius: 8px;
                position: relative;
                &::before {
                    content: "\f10d";
                    font-size: 35px;
                    color: var(--color-primary);
                    position: absolute;
                    padding-bottom: 0;
                    display: inline-block;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 400;
                    text-align: center;
                    top: 45px;
                    background: 0 0;
                }
                cite {
                    font-size: 15px;
                    display: block;
                    margin-top: 10px;
                    &::before {
                        content: "";
                        font-size: 28px;
                        color: #ff5421;
                        padding-bottom: 0px;
                        display: inline-block;
                        background: var(--color-primary);
                        height: 2px;
                        width: 40px;
                        font-weight: 400;
                        text-align: center;
                        top: -4px;
                        margin-right: 10px;
                        position: relative;
                    }
                }
            } 
        } 
    }
}


.rts-blog-area{
    &.style-six{
        .title-area{
            .title{
                color: #0B101C;
            }
        }
        .single-blog-one-wrapper{
            .blog-content{
                p{
                    color: #0B101C;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    span{
                        color: #FA360A;
                    }
                }
                a{
                    .title{
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        color: #0B101C;
                    }
                }
                a{
                    &.rts-read-more{
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        color: #0B101C;
                        i{
                            background: #ECECEC;
                            color: #000;
                        }
                        &:hover{
                            i{
                                background: var(--color-primary-5);
                                color: #ffff;
                            }
                        }
                    }
                }
            }
        }
        .single-blog-one-wrapper .thumbnail .blog-badge{
            background: var(--color-primary-5);
        }
    }
}