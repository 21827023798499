
// team details style hear
.team-details-support-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    i{
        height: 60px;
        width: 60px;
        border-radius: 15px;
        border: 1px solid #EDEDED;
        box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
        display: flex;align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: var(--color-primary);
    }
    .support-innner{
        margin-left: 20px;
        span{
            margin-bottom: 5px;
            color: #5D666F;
            font-size: 16px;
            font-weight: 400;
        }
        a{
            .title{
                margin-bottom: 0;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
}
p.disc {
    font-size: 16px;
    line-height: 26px;
}
.details-right-inner{
    .title-area{
        margin-bottom: 16px;
        span{
            color: #5D666F;
            font-weight: 500;
            font-size: 16px;
        }
        .title{
            margin-top: 0px;
            margin-bottom: 12px;
            font-size: 40px;
        }
    }
    p{
        &.disc{
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 33px;
            color: #5D666F;
        }
    }

    .rts-btn{
        margin-top: 30px;
        display: block;
        max-width: max-content;
    }
}


.single-about-skill-inner{
    .title{
        position: relative;
        margin-bottom: 40px;
        &::after{
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #F1F1F1;
        }
    }
}


.rts-progress-one-wrapper{
    .meter {
    // background: #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
    display: block;
    height: 8px;
    margin-bottom: 10px;
    // padding: 8px;
    position: relative;
  
  > span {
    display: block;
    height: 100%;
    background-color: var(--color-primary);
    position: relative;
    overflow: hidden;
    transition: width 2s ease-out;
  }
}

.orange {
  > span {
    background-color: var(--color-primary);
  }
}

.red {
  > span {
    background-color: var(--color-primary);
  }
}

.cadetblue {
  >span {
    background-color: var(--color-primary);
  }
}
}

.rts-progress-one-wrapper{
    .single-progress{
        margin-bottom: 20px;
        border-bottom: 1px solid #F1F1F1;
    }
    .progress-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        p{
            margin-bottom: 0;
            color: var(--color-primary);
            font-weight: 500;
            font-size: 16px;
        }
        .persectage{
            color: var(--color-primary);
            font-weight: 500;
            font-size: 16px;
        }
    }
}

.education-skill-wrapper{
    display: flex;
    box-shadow: 0px 13px 21px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    .number-area{
        position: absolute;
        height: 60px;
        width: 60px;
        background: var(--color-primary);
        border-radius: 50%;
        left: -4%;
        top: -10%;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            font-size: 22px;
            color: #fff;
            margin-left: auto;
            margin-top: auto;
            margin-right: 12px;
            margin-bottom: 8px;        
            span{
                font-size: 12px;
                color: #fff;
                margin-left: -3px;
            }
        }
    }
    .single-skill{
        padding: 30px 60px;
        border: 1px solid #F1F1F1;
        width: 50%;
        position: relative;
        overflow: hidden;
        transition: .3s;
        background: #fff;
        @media #{$laptop-device} {
            padding: 30px 11px 30px 50px;
        }
        @media #{$smlg-device} {
            padding: 30px 10px 30px 40px;
            width: 100%;
        }
        @media #{$sm-layout} {
            padding: 30px 10px 30px 50px;
        }
        .experience{
            margin-bottom: 3px;
        }
        .date{
            span{
                color: var(--color-primary);
            }
        }
        &:hover{
            transform: scale(1.05);
        }
    }
}


.single-contact-one-inner{
    background: #FFFFFF;
    border-radius: 15px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 40px;
    background-color: #fff9ec;
    flex-direction: column;
    justify-content: flex-start;
    .thumbnail{
        display: block;
        overflow: hidden;
        border-radius: 15px 15px 0 0;
        img{
            width: 100%;
            transition: .3s;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .content{
        display: flex;
        align-items: flex-start;
        background-color: #fff9ec;
        flex-direction: column;
        justify-content: flex-start;
        @media #{$sm-layout} {
            padding: 15px;
        }
        .icone{
            margin-bottom: 30px;
        }
        .info{
            span{
                margin-bottom: 0;
                color: var(--color-primary);
                font-weight: 500;
            }
            a{
                h5{
                    margin-bottom: 0;
                    transition: .3s;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    @media #{$lg-layout} {
                        font-size: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 18px;
                    }
                }
                &:hover{
                    h5{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}