
// counter up style song...


.counter-bg{
    background-image: url(../images/counterup/bg-01.jpg);
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    @media #{$laptop-device} {
        height: auto;
    }
    @media #{$smlg-device} {
        height: auto;
    }
    &.bg-2{
        height: auto;
    }
}

.single-counter{
    display: flex;
    align-items: center;
    &.two{
        @media #{$large-mobile} {
            justify-content: flex-start !important;
            padding-left: 0 !important;
            margin-top: 30px;
        }
    }
    &.three{
        @media #{$md-layout} {
            justify-content: flex-start !important;
            padding-left: 0 !important;
            margin-top: 30px;
        }
        @media #{$sm-layout} {
            padding-left: 0 !important;
            margin-top: 30px !important;
        }
        @media #{$large-mobile} {
            justify-content: flex-start !important;
        }
    }
    &.four{
        @media #{$smlg-device} {
            justify-content: flex-start !important;
            padding-left: 0 !important;
            margin-top: 30px;
        }
        @media #{$md-layout} {
            justify-content: flex-start !important;
            padding-left: 61px !important;
            margin-top: 30px;
        }
        @media #{$sm-layout} {
            justify-content: flex-start !important;
            padding-left: 32px !important;
            margin-top: 30px;
        }
        @media #{$large-mobile} {
            justify-content: flex-start !important;
            padding-left: 0 !important;
        }
    }
    img{
        margin-right: 25px;
    }
    .counter-details{
        .title{
            color: #fff;
            margin-bottom: -2px;
            font-weight: 700;
            span{
                position: relative;
                @media #{$laptop-device} {
                    font-size: 32px;
                }
                @media #{$sm-layout} {
                    font-size: 26px;
                }
                &::after{
                    position: absolute;
                    right: -30px;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '+';
                }
            }
            &.happy{
                span{
                    &::after{
                        content: 'k+';
                        right: -60px;
                        @media #{$sm-layout} {
                            right: -40px;
                        }
                    }
                }
            }
        }
        p{
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            @media #{$laptop-device} {
                font-size: 12px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
        }
    }
}



.counter-wrapper-two{
    .single-counter{
        position: relative;
        img{
            margin-right: 0;
            position: absolute;
            border-radius: 50%;
        }
        .counter-details{
            padding: 20px;
            box-shadow: 0px 2px 20px #18101012;
            width: 100%;
            padding: 30px;
            @media #{$smlg-device} {
                padding: 30px;
            }
            @media #{$small-mobile} {
                padding: 20px;
            }
            .title{
                color: #1C2539;
                margin-left: 110px;
            }
            .disc{
                color: #1C2539;
                margin-left: 110px;
            }
        }
    }
}

.counter-5{
    background: #E9EFFF;
    .single-counter{
        .counter-details{
            .title{
                color: #000;
            }
            p{
                color: #000;
            }
        }
    }
}