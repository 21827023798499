
// trusted client

.title-area-client{
    position: relative;
    p{
        &.client-title{
            max-width: max-content;
            margin: auto;
            font-weight: 700;
            color: #1C2539;
            text-transform: uppercase;
        }
    }
    &::after{
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        width: 41%;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        margin-top: -14px;
        @media #{$smlg-device} {
            width: 38%;
        }
        @media #{$md-layout} {
            width: 35%;
        }
        @media #{$sm-layout} {
            width: 30%;
        }
        @media #{$large-mobile} {
            display: none;
        }
    }
    &::before{
        content: "";
        position: absolute;
        left: 59%;
        width: 100%;
        height: 2px;
        width: 41%;
        background: var(--color-primary);
        display: flex;
        align-items: center;
        margin-top: 15px;
        @media #{$smlg-device} {
            left: 62%;
        }
        @media #{$md-layout} {
            left: 65%;
            width: 35%;
        }
        @media #{$sm-layout} {
            left: 69%;
            width: 30%;
        }
        @media #{$large-mobile} {
            display: none;
        }
    }
}

.client-wrapper-one{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{$laptop-device} {
        justify-content: center;
    }
    @media #{$smlg-device} {
        justify-content: center;
    }
    @media #{$sm-layout} {
        position: relative;
        z-index: 50;
    }
    a{
        img{
            box-shadow: 0px 10px 30px rgba(33, 5, 5, 0.05);
            border-radius: 15px;
            transition: var(--transition);
            @media #{$laptop-device} {
                margin-right: 10px;
            }
            @media #{$smlg-device} {
                margin-right: 10px;
                margin-bottom: 10px;
            }
            @media #{$small-mobile} {
                
            }
        }
        &:hover{
            img{
                transform: scale(1.1) translateY(-5px);
            }
        }
    }
}

// client wrapper two
.client-bg{
    background-image: url(../images/client/bg.jpg);
    .client-two-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$smlg-device} {
            justify-content: center;
            flex-wrap: wrap;
        }
        img{
            cursor: pointer;
            transition: .3s;
            @media #{$laptop-device} {
                max-width: 15%;
            }
            @media #{$smlg-device} {
                margin-right: 10px;
                margin-bottom: 10px;
            }
            @media #{$small-mobile} {
                width: 50%;
            }
            &:hover{
                transform: scale(1.15);
            }
        }
    }
}

.rts-trusted-client2{
    .container{
        .title-area-client{
            &::before{
                background: var(--color-primary-4);
            }
            &::after{
                background: var(--color-primary-4);
            }
        }
    }
}


